<template>
  <div class="card card-custom position-relative">
    <LoaderPartial v-if="showLoader"></LoaderPartial>
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-1"
        id="kt_wizard_v1"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav border-bottom">
          <div class="wizard-steps p-8 p-lg-10">
            <div class="wizard-step" data-wizard-type="step">
              <div :class="`wizard-label row ${client.id ? 'edit' : ''}`">
                <i class="wizard-icon flaticon-book col"></i>
                <h3 class="wizard-title col">Información General</h3>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div :class="`wizard-label row ${client.id ? 'edit' : ''}`">
                <i class="wizard-icon flaticon-piggy-bank col"></i>
                <h3 class="wizard-title col">Creditos Cliente</h3>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div :class="`wizard-label row ${client.id ? 'edit' : ''}`">
                <i class="wizard-icon flaticon-list col"></i>
                <h3 class="wizard-title col">Facturas Cliente</h3>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div :class="`wizard-label row ${client.id ? 'edit' : ''}`">
                <i class="wizard-icon flaticon-network col"></i>
                <h3 class="wizard-title col">Usuarios de Administración</h3>
              </div>
            </div>
            <!-- <div class="wizard-step" data-wizard-type="step">
              <div :class="`wizard-label row ${(client.id) ? 'edit' : ''}`">
                <i class="wizard-icon col flaticon-presentation"></i>
                <h3 class="wizard-title col">Estadisticas de uso</h3>
              </div>
              <i class="wizard-arrow last flaticon2-next" v-if="!client.id"></i>
            </div> -->
          </div>
        </div>
        <!--end: Wizard Nav-->

        <!--begin: Wizard Body-->
        <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
          <div class="col-12">
            <!--begin: Wizard Form-->
            <div class="form row justify-content-center" id="kt_form">
              <!--begin: Wizard Step 1-->
              <div
                class="pb-5 col-xl-12 col-xxl-11 .offset-xxl-6"
                data-wizard-type="step-content"
              >
                <h3 class="mb-10 font-weight-bold text-dark">
                  Información general
                </h3>
                <div class="form-group">
                  <label>Logo</label>
                  <div>
                    <div
                      class="image-input image-input-outline"
                      id="kt_profile_avatar"
                    >
                      <div
                        class="image-input-wrapper"
                        :style="{
                          backgroundImage: `url('${photo}')`,
                          backgroundPosition: 'center center'
                        }"
                      ></div>
                      <label
                        class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="change"
                        data-toggle="tooltip"
                        title=""
                        data-original-title="Change avatar"
                      >
                        <i class="fa fa-pen icon-sm text-muted"></i>
                        <input
                          type="file"
                          name="profile_avatar"
                          accept=".png, .jpg, .jpeg"
                          @change="onFileChange($event)"
                        />
                        <input type="hidden" name="profile_avatar_remove" />
                      </label>
                      <!-- <span
                        class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="cancel"
                        data-toggle="tooltip"
                        title="Cancel avatar"
                      >
                        <i class="ki ki-bold-close icon-xs text-muted"></i>
                      </span> -->
                    </div>
                    <span class="form-text text-muted"
                      >Tipos permitidos: png, jpg, jpeg.</span
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label>Nombre del cliente</label>
                  <input
                    ref="client"
                    type="text"
                    name="client"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="Nombre del cliente"
                    v-model="client.client"
                  />
                  <span class="form-text text-muted"
                    >Ingrese el nombre del cliente</span
                  >
                  <div
                    class="error text-primary"
                    v-if="!$v.client.client.required"
                  >
                    Este campo es obligatorio
                  </div>
                  <div
                    class="error text-primary"
                    v-if="!$v.client.client.minLength"
                  >
                    Son requeridos por lo menos 3 caracteres.
                  </div>
                </div>
                <div class="form-group">
                  <label>Nombre de la instancia</label>
                  <input
                    ref="name"
                    type="text"
                    name="name"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="Nombre de la instancia"
                    v-model="client.name"
                  />
                  <span class="form-text text-muted"
                    >Ingrese el nombre del cliente</span
                  >
                  <div
                    class="error text-primary"
                    v-if="!$v.client.name.required"
                  >
                    Este campo es obligatorio
                  </div>
                  <div
                    class="error text-primary"
                    v-if="!$v.client.name.minLength"
                  >
                    Son requeridos por lo menos 3 caracteres.
                  </div>
                  <div
                    class="error text-primary"
                    v-if="!$v.client.name.notExists && !$v.client.name.$pending"
                  >
                    El nombre de la instancia ya existe.
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <label>Descripción</label>
                      <textarea
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="description"
                        placeholder="Descripción del servicio"
                        v-model="client.description"
                      ></textarea>
                      <span class="form-text text-muted"
                        >Agrega una descripción para el servicio</span
                      >
                      <div
                        class="error text-primary"
                        v-if="!$v.client.description.required"
                      >
                        Este campo es obligatorio
                      </div>
                      <div
                        class="error text-primary"
                        v-if="!$v.client.description.minLength"
                      >
                        Son requeridos por lo menos 25 caracteres.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Subdominio de Instancia</label>
                  <input
                    type="text"
                    class="form-control form-control-solid form-control-lg"
                    name="id"
                    placeholder="Subdominio de la Instancia"
                    :disabled="client.id ? 'disabled' : false"
                    v-model="client.tenant"
                  />
                  <span class="form-text text-muted"
                    >Identificador de acceso a la instancia</span
                  >
                  <div
                    class="error text-primary"
                    v-if="!$v.client.tenant.required"
                  >
                    Este campo es obligatorio
                  </div>
                  <div
                    class="error text-primary"
                    v-if="!$v.client.tenant.minLength"
                  >
                    Son requeridos por lo menos 3 caracteres.
                  </div>
                  <div
                    class="error text-primary"
                    v-if="
                      !$v.client.tenant.notExists && !$v.client.tenant.$pending
                    "
                  >
                    El nombre de subdominio ya existe.
                  </div>
                </div>
              </div>
              <!--end: Wizard Step 1-->

              <!--begin: Wizard Step 2-->
              <div
                class="pb-5 col-xl-12 col-xxl-11 .offset-xxl-6"
                data-wizard-type="step-content"
              >
                <div class="row">
                  <h4 class="mb-10 font-weight-bold text-dark col">
                    Creditos del cliente
                  </h4>
                  <h5
                    class="mb-10 font-weight-bold text-dark col text-right"
                    v-if="wallet"
                  >
                    <b>{{ wallet.credits | amount }}</b> creditos disponibles
                  </h5>
                </div>
                <div class="form-group" v-if="!client.id">
                  <label>Creditos iniciales</label>
                  <div class="input-group input-group-lg input-group-solid">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="la">C</i>
                      </span>
                    </div>
                    <input
                      ref="initial-credits"
                      type="number"
                      min="1"
                      name="initial-credits"
                      class="form-control form-control-lg form-control-solid"
                      placeholder="Creditos del cliente"
                      v-model="client.credits"
                    />
                  </div>
                  <span class="form-text text-muted"
                    >Los creditos se depositarán al momento de crear al
                    cliente</span
                  >
                </div>
                <div v-if="client.id">
                  <div class="form-group">
                    <label>Creditos a recargar</label>
                    <div class="input-group input-group-lg input-group-solid">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="la">C</i>
                        </span>
                      </div>
                      <input
                        ref="credit-recarge"
                        type="number"
                        name="credit-recarge"
                        class="form-control form-control-lg form-control-solid"
                        placeholder="Numero de creditos"
                        @keydown="filterKey"
                        v-model.number="recharge.credits"
                      />
                    </div>
                    <div
                      class="error text-primary"
                      v-if="!$v.recharge.credits.required"
                    >
                      Este campo es obligatorio
                    </div>
                    <br />
                    <textarea
                      ref="comment-recarge"
                      name="comment-recarge"
                      class="form-control form-control-lg form-control-solid"
                      placeholder="Descripción de la recarga"
                      v-model="recharge.comment"
                    ></textarea>
                    <br />
                    <div style="text-align:right;">
                      <button
                        class="btn btn-primary btn-lg"
                        ref="kt_recharge_wallet"
                        @click="rechargeWallet($event)"
                        :disabled="$v.recharge.$invalid"
                      >
                        Recargar
                      </button>
                    </div>
                  </div>
                  <div class="form-group">
                    <h4 class="mb-10 font-weight-bold text-dark">
                      Historial de recargas
                    </h4>
                    <div class="table-responsive">
                      <table
                        class="table table-head-custom table-vertical-center table-head-bg table-borderless"
                      >
                        <thead>
                          <tr class="text-left">
                            <th class="">Fecha</th>

                            <!-- <th style="min-width: 100px">Suscripción</th> -->
                            <th class="">Créditos</th>

                            <th>
                              <span class="text-dark-75">Comentario </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody v-if="wallet.operations">
                          <template
                            v-for="(item, i) in wallet.operations.slice(
                              (currentPage - 1) * perPage,
                              currentPage * perPage
                            )"
                          >
                            <tr v-bind:key="i">
                              <td>
                                <span class="font-weight-bold">{{
                                  new Date(item.createdAt)
                                    .toLocaleString()
                                    .split(" ")[0]
                                }}</span>
                              </td>

                              <td>
                                <span
                                  class="font-weight-bold"
                                  :style="{
                                    color:
                                      parseInt(item.credits) < 0
                                        ? 'red!important'
                                        : 'black'
                                  }"
                                  >{{ item.credits | amount }}</span
                                >
                              </td>

                              <td>
                                <span
                                  class="font-weight-bold"
                                  :class="item.comment ? '' : 'text-muted'"
                                  >{{ item.comment || "Sin comentarios" }}</span
                                >
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                      <div>
                        <div class="row">
                          <div class="col m-1 " v-if="wallet.operations">
                            <b-pagination
                              v-model="currentPage"
                              :total-rows="wallet.operations.length"
                              :per-page="perPage"
                              page-click=""
                              aria-controls="my-table"
                            ></b-pagination>
                          </div>
                          <div class="col m-1 text-right">
                            Elementos por pagina
                            <b-form-select
                              v-model="perPage"
                              :options="optionPerPage"
                              class="mb-3"
                              style="width:70px;"
                              value-field="item"
                              text-field="name"
                              disabled-field="notEnabled"
                            ></b-form-select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Wizard Step 2-->

              <!--begin: Wizard Step 3-->
              <div
                class="pb-5 col-xl-12 col-xxl-11 .offset-xxl-6"
                data-wizard-type="step-content"
              >
                <template>
                  <v-dialog
                    v-model="billingDialog"
                    max-width="520"
                    persistent
                    :retain-focus="false"
                  >
                    <v-card class="py-5 px-2">
                      <v-card-title class="headline">
                        <span>¿Está seguro que desea borrar esta factura?</span>
                      </v-card-title>
                      <v-card-text>
                        Esta acción no puede ser reestablecida. Si aun así desea
                        continuar presione en eliminar permanentemente.
                        <br />
                      </v-card-text>

                      <v-card-actions class="justify-content-center">
                        <button
                          class="btn btn-secondary font-weight-bolder font-size-sm mr-3"
                          @click="
                            () => {
                              billingDialog = false;
                            }
                          "
                        >
                          Cancelar
                        </button>
                        <button
                          class="btn btn-primary font-weight-bolder font-size-sm mr-3"
                          @click="
                            () => {
                              billingDialog = false;
                              deleteBilling(deletableBilling.id);
                            }
                          "
                        >
                          Eliminar permanentemente
                        </button>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
                <div class="row">
                  <h4 class="mb-10 font-weight-bold text-dark col">
                    Facturación
                  </h4>
                </div>
                <div class="form-group">
                  <label>Fecha de facturación</label>
                  <b-form-datepicker
                    id="datepicker-facturation-date"
                    class="form-control form-control-solid form-control-lg"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric'
                    }"
                    v-model="billing.date"
                    locale="es"
                  ></b-form-datepicker>
                  <div
                    class="error text-primary"
                    v-if="!$v.billing.date.required"
                  >
                    Este campo es obligatorio
                  </div>
                </div>

                <div class="form-group">
                  <label>Cantidad a facturar</label>
                  <div class="input-group input-group-lg input-group-solid">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="la">$</i>
                      </span>
                    </div>
                    <input
                      ref="credit-recarge"
                      type="number"
                      name="credit-recarge"
                      min="1"
                      class="form-control form-control-lg form-control-solid"
                      placeholder="Numero de creditos"
                      @keydown="filterKey"
                      v-model.number="billing.amount"
                    />
                  </div>
                  <div
                    class="error text-primary"
                    v-if="!$v.billing.amount.required"
                  >
                    Este campo es obligatorio
                  </div>
                  <div
                    class="error text-primary"
                    v-if="!$v.billing.amount.minValue"
                  >
                    La cantidad a facturar debe ser minimo de 1.
                  </div>
                </div>
                <div class="form-group">
                  <label>Descripción</label>
                  <textarea
                    ref="comment-recarge"
                    name="comment-recarge"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="Descripción de la recarga"
                    v-model="billing.description"
                  ></textarea>
                </div>
                <div class="form-group">
                  <label>Factura (Solo PDF)</label>
                  <b-form-file
                    class="form-control form-control-lg form-control-solid"
                    @change="onBillFileChange($event)"
                    :state="Boolean(billing.url)"
                    placeholder="Elige una factura o arrastrala aqui..."
                    drop-placeholder="Arrastra aqui el archivo..."
                    accept=".pdf"
                    id="facturation"
                  >
                  </b-form-file>
                  <div
                    class="error text-primary"
                    v-if="!$v.billing.url.required"
                  >
                    Este campo es obligatorio
                  </div>
                </div>
                <div style="text-align:right;">
                  <button
                    class="btn btn-primary btn-lg"
                    ref="kt_create_billing"
                    @click="createBilling($event)"
                    :disabled="$v.billing.$invalid"
                  >
                    Subir factura
                  </button>
                </div>
                <div class="form-group">
                  <h4 class="mb-10 font-weight-bold text-dark">
                    Historial de facturas
                  </h4>
                  <div class="table-responsive">
                    <table
                      class="table table-head-custom table-vertical-center table-head-bg table-borderless"
                    >
                      <thead>
                        <tr class="text-left">
                          <th style="" class="">Fecha</th>
                          <th style="" class="">Factura ID</th>
                          <th style="" class="pl-7">
                            <span class="">Descripción</span>
                          </th>
                          <th style="">Cantidad</th>
                          <th style=""></th>
                        </tr>
                      </thead>
                      <tbody v-if="wallet.bills">
                        <template
                          v-for="(item, i) in wallet.bills.slice(
                            (billsCurrentPage - 1) * billsPerPage,
                            billsCurrentPage * billsPerPage
                          )"
                        >
                          <tr v-bind:key="i">
                            <td>
                              <span class="font-weight-bold"
                                >{{
                                  item.date
                                    .split("-")
                                    .reverse()
                                    .join("/")
                                }}
                                <!-- {{
                                (new Date(item.date)).toLocaleString().split(" ")[0]
                              }} -->
                              </span>
                            </td>
                            <td>
                              <a class="font-weight-bold" :href="item.url">
                                <i class="flaticon-download"></i> {{ item.id }}
                              </a>
                            </td>
                            <td>
                              <span
                                class="font-weight-bold"
                                :class="item.description ? '' : 'text-muted'"
                                >{{
                                  item.description || "Sin descripción"
                                }}</span
                              >
                            </td>
                            <td>
                              <span
                                class="font-weight-bold"
                                :style="{
                                  color:
                                    parseInt(item.amount) < 0
                                      ? 'red!important'
                                      : 'black'
                                }"
                                >{{ item.amount | currency }}</span
                              >
                            </td>
                            <td>
                              <b-badge
                                variant="primary"
                                class="m-0"
                                style="cursor:pointer;"
                                @click="
                                  () => {
                                    billingDialog = true;
                                    deletableBilling = item;
                                  }
                                "
                                >✖︎</b-badge
                              >
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                    <div>
                      <div class="row">
                        <div class="col m-1 " v-if="wallet.bills">
                          <b-pagination
                            v-model="billsCurrentPage"
                            :total-rows="wallet.bills.length"
                            :per-page="billsPerPage"
                            page-click=""
                            aria-controls="my-table"
                          ></b-pagination>
                        </div>
                        <div class="col m-1 text-right">
                          Elementos por pagina
                          <b-form-select
                            v-model="billsPerPage"
                            :options="optionPerPage"
                            class="mb-3"
                            style="width:70px;"
                            value-field="item"
                            text-field="name"
                            disabled-field="notEnabled"
                          ></b-form-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Wizard Step 3-->

              <!--begin: Wizard Step 4-->
              <div class="pb-5 col-12" data-wizard-type="step-content">
                <div
                  class="card card-custom card-stretch gutter-b"
                  style="box-shadow:none;"
                >
                  <!--begin::Header-->
                  <div class="card-header border-0 pb-5 pt-0">
                    <h2
                      class="card-title align-items-start flex-column font-weight-bold text-dark col pt-0 mt-0"
                    >
                      <span class="card-label font-weight-bolder text-dark"
                        >Listado de usuarios</span
                      >
                      <span
                        class="text-muted mt-3 font-weight-bold font-size-sm"
                        >{{ rows }} Administradores encontradas en el
                        sistema</span
                      >
                      <div
                        class="error text-primary"
                        v-if="!$v.client.client.required"
                      >
                        Este campo es obligatorio
                      </div>
                      <div
                        class="error text-primary"
                        v-if="!$v.client.client.minLength"
                      >
                        Son requeridos por lo menos 3 caracteres.
                      </div>
                    </h2>
                    <div class="card-toolbar">
                      <button
                        v-b-modal.tenant-admin-modal
                        @click="
                          () => {
                            user = {};
                            asignId('new');
                          }
                        "
                        class="btn btn-primary font-weight-bolder font-size-sm mr-3"
                      >
                        Nuevo Usuario
                      </button>

                      <b-modal
                        id="tenant-admin-modal"
                        ref="tenant-admin-modal"
                        hide-footer
                        :title="
                          `${
                            !client.id
                              ? 'Crear nuevo administrador'
                              : 'Editar administrador'
                          }`
                        "
                      >
                        <div class="form">
                          <!--begin::Body-->
                          <div class="card-body">
                            <div class="form-group ">
                              <label>Nombre completo</label>
                              <input
                                ref="name"
                                class="form-control form-control-lg form-control-solid"
                                type="text"
                                v-model="user.name"
                              />
                              <div
                                class="error text-primary"
                                v-if="!$v.user.name.required"
                              >
                                Este campo es obligatorio
                              </div>
                              <div
                                class="error text-primary"
                                v-if="!$v.user.name.minLength"
                              >
                                Son requeridos por lo menos 5 caracteres.
                              </div>
                            </div>
                            <div class="form-group ">
                              <label>Alias</label>
                              <input
                                ref="nickname"
                                class="form-control form-control-lg form-control-solid"
                                type="text"
                                v-model="user.nickname"
                              />
                              <div
                                class="error text-primary"
                                v-if="!$v.user.nickname.required"
                              >
                                Este campo es obligatorio
                              </div>
                              <div
                                class="error text-primary"
                                v-if="!$v.user.nickname.minLength"
                              >
                                Son requeridos por lo menos 5 caracteres.
                              </div>
                              <div
                                class="error text-primary"
                                v-if="
                                  !$v.user.nickname.notExists &&
                                    !$v.user.nickname.$pending
                                "
                              >
                                El alias ingresado ya existe.
                              </div>
                              <div
                                class="error text-primary"
                                v-if="!$v.user.nickname.regex"
                              >
                                El alias no puede contener espacios ni
                                caracteres especiales.
                              </div>
                            </div>

                            <div class="form-group ">
                              <label>Correo Electrónico</label>
                              <div
                                class="input-group input-group-lg input-group-solid"
                              >
                                <div class="input-group-prepend">
                                  <span class="input-group-text">
                                    <i class="la la-at"></i>
                                  </span>
                                </div>
                                <input
                                  ref="email"
                                  type="text"
                                  class="form-control form-control-lg form-control-solid"
                                  placeholder="Email"
                                  :readonly="!!user.id"
                                  v-model="user.email"
                                />
                              </div>
                              <div
                                class="error text-primary"
                                v-if="!$v.user.email.required"
                              >
                                Este campo es obligatorio
                              </div>
                              <div
                                class="error text-primary"
                                v-if="!$v.user.email.email"
                              >
                                Formato de email no válido
                              </div>
                              <div
                                class="error text-primary"
                                v-if="
                                  !$v.user.email.notExists &&
                                    !$v.user.email.$pending
                                "
                              >
                                El email ingresado ya existe.
                              </div>
                            </div>
                            <div class="form-group " v-if="!user.id">
                              <label>Contraseña</label>
                              <input
                                ref="password"
                                class="form-control form-control-lg form-control-solid"
                                type="password"
                                v-model="user.password"
                              />
                              <div
                                class="error text-primary"
                                v-if="!$v.user.password.required"
                              >
                                Este campo es obligatorio
                              </div>
                              <div
                                class="error text-primary"
                                v-if="!$v.user.password.minLength"
                              >
                                Son requeridos por lo menos 8 caracteres.
                              </div>
                            </div>
                            <div class="form-group " v-if="!user.id">
                              <label>Repetir Contraseña</label>
                              <input
                                ref="password"
                                class="form-control form-control-lg form-control-solid"
                                type="password"
                                v-model="user.repeatPassword"
                              />
                              <div
                                class="error text-primary"
                                v-if="!$v.user.repeatPassword.sameAsPassword"
                              >
                                Las contraseñas no coinciden
                              </div>
                            </div>

                            <div class="form-group row" v-if="!!user.id">
                              <label class="col-12 col-form-label"
                                >Fecha de creación</label
                              >

                              <div class="col-12">
                                {{ user.createdAt }}
                              </div>
                            </div>
                            <div class="text-right">
                              <button
                                class="btn btn-secondary font-weight-bolder font-size-sm"
                                @click="
                                  () => {
                                    $refs['tenant-admin-modal'].hide();
                                    user = {};
                                  }
                                "
                              >
                                Cancelar
                              </button>
                              <button
                                class="btn btn-primary font-weight-bolder font-size-sm"
                                ref="kt_update_user"
                                :disabled="$v.editUser.$invalid"
                                v-if="!!user.id"
                                @click="
                                  () => {
                                    $refs['tenant-admin-modal'].hide();
                                    updateUser();
                                  }
                                "
                              >
                                Guardar
                              </button>
                              <button
                                :disabled="$v.user.$invalid"
                                ref="kt_submit_user"
                                class="btn btn-primary font-weight-bolder font-size-sm"
                                v-if="!user.id"
                                @click="
                                  () => {
                                    $refs['tenant-admin-modal'].hide();
                                    submitUser();
                                  }
                                "
                              >
                                Crear usuario
                              </button>
                            </div>
                          </div>
                          <!--end::Body-->
                        </div>
                      </b-modal>
                      <!-- <a href="#" class="btn btn-danger font-weight-bolder font-size-sm"
                          >Create</a
                        > -->
                    </div>
                  </div>
                  <!--end::Header-->
                  <!--begin::Body-->
                  <div class="card-body pt-0 pb-3">
                    <div>
                      <div>
                        <div
                          class="input-group input-group-lg input-group-solid mb-8"
                        >
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="la la-search"></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            class="form-control form-control-solid form-control-lg inline"
                            name="address1"
                            placeholder="Busqueda"
                            v-model="search"
                            append-icon="search"
                            label="Search"
                            hide-details
                          />
                        </div>
                      </div>
                      <div class="tab-content">
                        <!--begin::Table-->
                        <div class="table-responsive">
                          <table
                            class="table table-head-custom table-vertical-center table-head-bg table-borderless"
                          >
                            <thead>
                              <tr class="text-left">
                                <th
                                  style="min-width: 100px"
                                  class="hover"
                                  @click="userSortListFor('email')"
                                >
                                  Email
                                  <i
                                    class="fa fa-angle-up"
                                    aria-hidden="true"
                                    v-if="userSortingList.email == 'DESC'"
                                  ></i>
                                  <i
                                    class="fa fa-angle-down"
                                    aria-hidden="true"
                                    v-if="userSortingList.email == 'ASC'"
                                  ></i>
                                </th>
                                <th style="min-width: 100px">Estado</th>
                                <th
                                  style="min-width: 100px"
                                  class="hover"
                                  @click="userSortListFor('createdAt')"
                                >
                                  Creación
                                  <i
                                    class="fa fa-angle-up"
                                    aria-hidden="true"
                                    v-if="userSortingList.createdAt == 'DESC'"
                                  ></i>
                                  <i
                                    class="fa fa-angle-down"
                                    aria-hidden="true"
                                    v-if="userSortingList.createdAt == 'ASC'"
                                  ></i>
                                </th>
                                <th style="min-width: 100px"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <template v-for="(item, i) in admins">
                                <tr v-bind:key="i">
                                  <td class="pl-0 py-8">
                                    <div class="d-flex align-items-center">
                                      <div
                                        class="symbol symbol-50 symbol-light mr-4"
                                      >
                                        <span
                                          class="symbol-label"
                                          style="overflow:hidden;"
                                          v-if="!item.image"
                                        >
                                          <inline-svg
                                            src="media/svg/icons/General/User.svg"
                                          />
                                        </span>
                                        <span
                                          class="symbol-label"
                                          style="overflow:hidden;"
                                          v-else
                                        >
                                          <img
                                            :src="item.image"
                                            class="h-100 align-self-end"
                                            alt=""
                                          />
                                        </span>
                                      </div>
                                      <div>
                                        <a
                                          v-b-modal.tenant-admin-modal
                                          @click="
                                            () => {
                                              user = { ...item };
                                              asignId(item.id);
                                            }
                                          "
                                          style="cursor:pointer"
                                          class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                                          >{{ item.email }}</a
                                        >
                                        <!-- <span class="text-muted font-weight-bold d-block" v-if="!!item.nickname">{{item.nickname}}</span>
                                        <span class="text-muted font-weight-bold d-block" v-if="!item.nickname">{{item.nickname}}</span> -->
                                      </div>
                                    </div>
                                  </td>
                                  <!-- <td>
                                    <span class="text-muted font-weight-bold">{{ item.suscriptions.join(", ") }}</span>
                                  </td> -->
                                  <td>
                                    <b-badge
                                      class="mr-1"
                                      variant="success"
                                      v-if="item.active"
                                      >Activo</b-badge
                                    >
                                    <b-badge
                                      class="mr-1"
                                      variant="danger"
                                      v-if="!item.active"
                                      >Inactivo</b-badge
                                    >
                                    <span
                                      class="text-muted font-weight-bold d-block"
                                      >{{ item.company_desc }}</span
                                    >
                                  </td>
                                  <td>
                                    <!-- <img
                                      src="/media/logos/stars.png"
                                      alt="image"
                                      style="width: 5rem"
                                    /> -->
                                    <span
                                      class="text-muted font-weight-bold d-block"
                                      >{{
                                        new Date(item.createdAt)
                                          .toLocaleString()
                                          .split(" ")[0]
                                      }}</span
                                    >
                                  </td>
                                  <td class="pr-0 text-right">
                                    <b-dropdown id="dropdown-1" text="Acciones">
                                      <b-dropdown-item
                                        v-b-modal.tenant-admin-modal
                                        @click="
                                          () => {
                                            user = { ...item };
                                            asignId(item.id);
                                          }
                                        "
                                        >Editar</b-dropdown-item
                                      >
                                      <b-dropdown-item
                                        v-if="item.active"
                                        @click="disableUser(item.id)"
                                        ><span style="color:red;"
                                          >Desactivar</span
                                        ></b-dropdown-item
                                      >
                                      <b-dropdown-item
                                        v-if="!item.active"
                                        @click="enableUser(item.id)"
                                        ><span style=""
                                          >Activar</span
                                        ></b-dropdown-item
                                      >
                                      <b-dropdown-item
                                        @click="deleteUserModal(item)"
                                        ><span style="color:red;"
                                          >Eliminar</span
                                        ></b-dropdown-item
                                      >
                                      <!-- <b-dropdown-divider></b-dropdown-divider>
                                      <b-dropdown-item active>Active action</b-dropdown-item>
                                      <b-dropdown-item disabled>Disabled action</b-dropdown-item> -->
                                    </b-dropdown>

                                    <template>
                                      <v-dialog
                                        v-model="dialog"
                                        max-width="520"
                                        persistent
                                        :retain-focus="false"
                                      >
                                        <v-card class="py-5 px-2">
                                          <v-card-title class="headline">
                                            <span
                                              >¿Está seguro que desea borrar
                                              este usuario?</span
                                            >
                                          </v-card-title>
                                          <v-card-text>
                                            Esta acción no puede ser
                                            reestablecida. Si aun así desea
                                            continuar, escriba el nombre del
                                            usuario (<b>{{
                                              deletableAdmin.email
                                            }}</b
                                            >) y luego presione en Eliminar
                                            permanentemente. <br /><br />
                                            <input
                                              type="text"
                                              class="form-control form-control-solid form-control-lg inline mb-2"
                                              name="deletable-name"
                                              :placeholder="
                                                `escribe: ${deletableAdmin.email}`
                                              "
                                              v-model="deletableAdminEmail"
                                              hide-details
                                            />
                                          </v-card-text>

                                          <v-card-actions
                                            class="justify-content-center"
                                          >
                                            <button
                                              class="btn btn-secondary font-weight-bolder font-size-sm mr-3"
                                              @click="
                                                () => {
                                                  dialog = false;
                                                }
                                              "
                                            >
                                              Cancelar
                                            </button>
                                            <button
                                              class="btn btn-primary font-weight-bolder font-size-sm mr-3"
                                              :disabled="
                                                deletableAdmin.email !=
                                                  deletableAdminEmail
                                              "
                                              @click="
                                                () => {
                                                  dialog = false;
                                                  deleteUser(deletableAdmin.id);
                                                }
                                              "
                                            >
                                              Eliminar permanentemente
                                            </button>
                                          </v-card-actions>
                                        </v-card>
                                      </v-dialog>
                                    </template>
                                  </td>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                          <div>
                            <div class="row">
                              <div class="col m-1 ">
                                <b-pagination
                                  v-model="userCurrentPage"
                                  :total-rows="userRows"
                                  :per-page="userPerPage"
                                  page-click=""
                                  aria-controls="my-table"
                                ></b-pagination>
                              </div>
                              <div class="col m-1 text-right">
                                Elementos por pagina
                                <b-form-select
                                  v-model="userPerPage"
                                  :options="optionPerPage"
                                  class="mb-3"
                                  style="width:70px;"
                                  value-field="item"
                                  text-field="name"
                                  disabled-field="notEnabled"
                                ></b-form-select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--end::Table-->
                      </div>
                    </div>
                  </div>
                  <!--end::Body-->
                </div>
              </div>
              <!--end: Wizard Step 4-->

              <!--begin: Wizard Step 5-->
              <div
                class="pb-5 col-xl-12 col-xxl-11 .offset-xxl-6"
                data-wizard-type="step-content"
              >
                <h3 class="mb-10 font-weight-bold text-dark">
                  Estadisticas de uso
                </h3>
                <div v-if="!client.id">
                  Debe crear el servicio antes de poder ver estadisticas
                </div>
                <div v-if="client.id">Estadisticas</div>
              </div>
              <!--end: Wizard Step 5-->

              <!--begin: Wizard Actions -->
              <div
                class="d-flex justify-content-between border-top pt-10 col-xl-12 col-xxl-9 .offset-xxl-6"
              >
                <div class="mr-2">
                  <!-- <button
                    class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-prev"
                    v-if="!client.id"
                  >
                    Anterior
                  </button> -->
                </div>
                <div>
                  <button
                    v-on:click="update"
                    ref="kt_update_client"
                    :disabled="$v.client.$invalid"
                    class="btn btn-success btn-lg"
                  >
                    Guardar
                  </button>
                </div>
              </div>
              <!--end: Wizard Actions -->
            </div>
            <!--end: Wizard Form-->
          </div>
        </div>
        <!--end: Wizard Body-->
      </div>
    </div>
    <!--end: Wizard-->
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-1.scss";
.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.wizard-label {
  display: flex;
  align-items: center;
}
.wizard-icon {
  font-size: 1.6em !important;
  margin: 0 !important;
  padding: 0 !important;
  max-width: 50px !important;
}
.wizard-title {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 1.2rem !important;
  text-align: left;
}
.wizard-steps {
  padding: 2rem 1rem;
}
</style>
<style v-if="client.id">
.wizard.wizard-1
  .wizard-nav
  .wizard-steps
  .wizard-step[data-wizard-state="done"]
  .edit.wizard-label
  .wizard-icon {
  color: #b5b5c3;
}
.wizard.wizard-1
  .wizard-nav
  .wizard-steps
  .wizard-step[data-wizard-state="done"]
  .edit.wizard-label
  .wizard-title {
  color: #7e8299;
}
</style>
<style scoped>
table th.hover {
  cursor: pointer;
}
table th.hover:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 700px;
    margin: 1.75rem auto;
  }
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import LoaderPartial from "@/view/content/LoaderPartial.vue";

import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  minValue,
  email,
  sameAs,
  helpers
} from "vuelidate/lib/validators";
const RESOURCE = "tenancies";

let userId = "new";
const cv = {
  tenant: {
    async subdomainNotExists(value) {
      if (!value) return true;
      var resource = await ApiService.query(RESOURCE, {
        filter: {
          limit: 1,
          where: {
            and: [{ tenant: value }]
          }
        }
      });
      let id = window.location.hash.split("/")?.pop();
      console.log("id", id);
      return resource.data.length == 0 || resource.data.pop().id == id;
    },
    async nameNotExists(value) {
      if (!value) return true;
      var resource = await ApiService.query(RESOURCE, {
        filter: {
          limit: 1,
          where: {
            and: [{ name: value }]
          }
        }
      });
      let id = window.location.hash.split("/")?.pop();
      console.log("id", id);
      return resource.data.length == 0 || resource.data.pop().id == id;
    }
  },
  user: {
    alphaNumAndDotValidator: helpers.regex("alphaNumAndDot", /^[a-z0-9._-]*$/i),
    async nicknameNotExists(value) {
      if (!value) return true;
      let id = window.location.hash.split("/")?.pop();

      var resource = await ApiService.query(`${RESOURCE}/${id}/admins`, {
        filter: {
          limit: 1,
          where: { nickname: value }
        }
      });

      return resource.data.length == 0 || resource.data.pop()?.id == userId;
    },
    async emailNotExists(value) {
      if (!value) return true;
      let id = window.location.hash.split("/")?.pop();

      var resource = await ApiService.query(`${RESOURCE}/${id}/admins`, {
        filter: {
          limit: 1,
          where: { email: value }
        }
      });

      return resource.data.length == 0 || resource.data.pop()?.id == userId;
    }
  }
};
// function resetFilePlaceholder(){
//   document.querySelector("#facturation").parentNode.querySelector("label span").innerText="Elige una factura o arrastrala aqui..."
// }
export default {
  mixins: [validationMixin],
  name: "ClientWizard",
  components: {
    LoaderPartial
  },
  data() {
    return {
      resource: "tenancies",
      billingFile: null,
      billing: {
        date: "",
        amount: 0,
        description: "",
        url: "",
        createdAt: ""
      },
      client: {
        image: null,
        client: "",
        name: "",
        description: "",
        tenant: "",
        credits: ""
      },
      search: "",
      user: {
        name: "",
        nickname: "",
        email: "",
        password: "",
        repeatPassword: ""
      },
      admins: [],
      wallet: {
        credits: 0,
        operations: [],
        bills: []
      },
      recharge: {
        credits: null,
        comment: ""
      },
      default_photo: "media/users/blank.png",
      current_photo: null,
      deletableBilling: {},
      deletableAdmin: {},
      deletableAdminEmail: "",
      currentPage: 1,
      rows: 0,
      perPage: 5,
      optionPerPage: [5, 10, 25, 50, 100],
      sortingList: {},
      billsCurrentPage: 1,
      billsRows: 0,
      billsPerPage: 5,
      billsOptionPerPage: [5, 10, 25, 50, 100],
      billsSortingList: {},
      userCurrentPage: 1,
      userRows: 0,
      userPerPage: 5,
      userOptionPerPage: [5, 10, 25, 50, 100],
      userSortingList: {},
      dialog: false,
      billingDialog: false,
      wizard: {},
      showLoader: true
    };
  },
  validations: {
    billing: {
      date: {
        required
      },
      amount: {
        required,
        minValue: minValue(1)
      },
      url: {
        required
      }
    },
    client: {
      client: {
        required,
        minLength: minLength(3)
      },
      name: {
        required,
        minLength: minLength(3),
        notExists: cv.tenant.nameNotExists
      },
      description: {
        required,
        minLength: minLength(25)
      },
      tenant: {
        required,
        minLength: minLength(3),
        notExists: cv.tenant.subdomainNotExists
      }
    },
    user: {
      name: {
        required,
        minLength: minLength(5)
      },
      nickname: {
        required,
        minLength: minLength(5),
        notExists: cv.user.nicknameNotExists,
        regex: cv.user.alphaNumAndDotValidator
      },
      email: {
        required,
        email,
        notExists: cv.user.emailNotExists
      },
      password: {
        required,
        minLength: minLength(8)
      },
      repeatPassword: {
        sameAsPassword: sameAs("password")
      }
    },
    recharge: {
      credits: {
        required
      }
    },

    editUser: ["user.name", "user.nickname", "user.email"],
    step: {
      1: [
        "client.name",
        "client.client",
        "client.description",
        "client.tenant"
      ],
      2: ["client.credits"],
      3: [
        "user.name",
        "user.nickname",
        "user.email",
        "user.password",
        "user.repeatPassword"
      ]
    }
  },
  async mounted() {
    console.log(
      this.currentPage * (this.perPage - 1),
      this.currentPage * this.perPage
    );

    if (this.$route.params.id) {
      let getClient;
      try {
        getClient = await this.getClient(this.$route.params.id);
        this.wallet = {
          ...(await this.getWallet(this.$route.params.id)),
          bills: await this.getBills(this.$route.params.id)
        };
        this.wallet?.operations?.sort(function(a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        this.wallet?.bills?.sort(function(a, b) {
          return new Date(b.date) - new Date(a.date);
        });
        this.getUserList();
      } catch (e) {
        console.log(404);
        this.$router.push({ name: "404" });
      }
      this.client = getClient;

      this.current_photo = this.client.image;
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Clientes", route: "/clients" },
        { title: "Editar " + getClient.name }
      ]);
    } else {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Clientes", route: "/clients" },
        { title: "Nuevo cliente" }
      ]);
    }

    // Initialize form wizard
    this.wizard = new KTWizard("kt_wizard_v1", {
      startStep: 1, // initial active step number
      clickableSteps: true // allow step clicking
    });

    // Validation before going to next page
    this.wizard.on("beforeNext", function(/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });

    // Change event
    this.wizard.on("change", function(/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });

    if (this.client.id) this.sortListFor("createdAt");

    this.showLoader = false;
  },
  methods: {
    filterKey(e) {
      const key = e.key;

      // If is '.' key, stop it
      if (key === ".") return e.preventDefault();

      // OPTIONAL
      // If is 'e' key, stop it
      if (key === "e") return e.preventDefault();
    },

    // This can also prevent copy + paste invalid character
    filterInput(e) {
      e.target.value = e.target.value.replace(/[^0-9]+/g, "");
    },
    asignId(id) {
      userId = id;
    },
    async updateUser() {
      let { id, ...user } = this.user;
      console.log(user);

      const submitButton = this.$refs["kt_update_user"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      submitButton.disabled = true;

      try {
        let { data } = await ApiService.patch(
          `${this.getUserResource()}/${id}`,
          user
        );
        console.log(data);
        this.user = {};
        Swal.fire({
          title: "",
          text: "El usuario ha sido guardado exitosamente!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary"
        });
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.disabled = false;
        this.getUserList();
      } catch (e) {
        console.log(e);
        Swal.fire({
          title: "El usuario no ha sido actualizado",
          text: "Por favor revisa que los datos ingresados sean correctos",
          icon: "error",
          confirmButtonClass: "btn btn-secondary"
        });
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.disabled = false;
      }
    },
    async submitUser() {
      let user = this.user;
      console.log(user);
      const submitButton = this.$refs["kt_submit_user"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      submitButton.disabled = true;
      try {
        let { data } = await ApiService.post(this.getUserResource(), user);
        console.log(data);
        this.user = {};
        Swal.fire({
          title: "",
          text: "El usuario ha sido guardado exitosamente!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary"
        });
        this.getUserList();
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.disabled = false;
      } catch (e) {
        console.log(e);
        Swal.fire({
          title: "El usuario no ha sido actualizado",
          text: "Por favor revisa que los datos ingresados sean correctos",
          icon: "error",
          confirmButtonClass: "btn btn-secondary"
        });
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.disabled = false;
      }
    },
    getUserResource() {
      return `${this.resource}/${this.$route.params.id}/admins`;
    },
    sortListFor(row) {
      if (!this.sortingList[row]) {
        this.sortingList = []; // Clear?
        this.sortingList[row] = "DESC";
      } else if (this.sortingList[row] == "ASC") this.sortingList[row] = "DESC";
      else if (this.sortingList[row] == "DESC") this.sortingList[row] = "ASC";

      //this.getUserList();
    },
    userSortListFor(row) {
      if (!this.userSortingList[row]) {
        this.userSortingList = []; // Clear?
        this.userSortingList[row] = "DESC";
      } else if (this.userSortingList[row] == "ASC")
        this.userSortingList[row] = "DESC";
      else if (this.userSortingList[row] == "DESC")
        this.userSortingList[row] = "ASC";

      this.getUserList();
    },
    getUserQuery() {
      let search = this.search.toLowerCase();
      let query = {
        filter: {
          fields: {
            id: true,
            nickname: true,
            email: true,
            name: true,
            createdAt: true,
            active: true
          },
          limit: this.userPerPage,
          skip: this.userPerPage * (this.userCurrentPage - 1),
          order: Object.keys(this.userSortingList).map(
            key => `${key} ${this.userSortingList[key]}`
          ),
          where: {
            or: [
              { name: { like: `${search}`, options: "i" } },
              { email: { like: `${search}`, options: "i" } },
              { id: { like: `${search}`, options: "i" } }
            ]
          }
        }
      };
      return query;
    },
    async getUserCount() {
      let { data } = await ApiService.query(`${this.getUserResource()}/count`, {
        where: this.getUserQuery().filter.where
      });
      this.userRows = data.count;
      return data.count;
    },
    async getUserList() {
      this.getUserCount();

      let admins = await ApiService.query(
        this.getUserResource(),
        this.getUserQuery()
      );
      console.log(admins);
      let data = admins.data;
      this.admins = data;
      // this.tenants = data.map(tenancy => {
      //   tenancy.suscriptions = [];
      //   tenancy.roles?.forEach(async role => {
      //     let tenantRole = await this.getUserRole(role)
      //     tenancy.suscriptions.push( tenantRole.name );
      //   })
      //   return tenancy;
      // });
      // this.tenants = data;
      console.log(data);
    },
    async getUserRole(id) {
      let { data } = await ApiService.get(
        `${this.getUserResource()}/roles`,
        id
      );
      //console.log(data);
      return data;
    },
    async deleteUser(id) {
      ApiService.delete(`${this.getUserResource()}/${id}`)
        .then(() => {
          this.getUserList();

          Swal.fire({
            title: "",
            text: `El usuario ${this.deletableAdminEmail} ha sido eliminado exitosamente!`,
            icon: "info",
            confirmButtonClass: "btn btn-secondary"
          });
          this.deletableAdmin = {};
          this.deletableAdminEmail = "";
        })
        .catch(() => {
          Swal.fire({
            title: "El usuario no ha sido eliminado",
            text: "Por favor revisa que los datos ingresados sean correctos",
            icon: "error",
            confirmButtonClass: "btn btn-secondary"
          });
        });
    },
    deleteUserModal(item) {
      console.log(item);
      this.deletableAdmin = item;
      this.dialog = true;
    },
    async disableUser(id) {
      ApiService.patch(`${this.getUserResource()}/${id}`, { active: false })
        .then(() => this.getUserList())
        .catch(() => {
          Swal.fire({
            title: "El usuario no ha sido desactivado",
            text: "Por favor vuelve a intentarlo más tarde",
            icon: "error",
            confirmButtonClass: "btn btn-secondary"
          });
        });
    },
    async enableUser(id) {
      ApiService.patch(`${this.getUserResource()}/${id}`, { active: true })
        .then(() => this.getUserList())
        .catch(() => {
          Swal.fire({
            title: "El usuario no ha sido activado",
            text: "Por favor vuelve a intentarlo más tarde",
            icon: "error",
            confirmButtonClass: "btn btn-secondary"
          });
        });
    },
    getUserRoleName(id) {
      return new Promise(resolve => {
        this.getUserRole(id).then(admin => {
          resolve(admin.name);
        });
      });
    },
    async onFileChange(e) {
      const file = e.target.files[0];

      if (!this.client.image) this.client.image = null;

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = event => {
          this.current_photo = event.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }

      let formData = new FormData();

      formData.append("image", file);

      let { data } = await ApiService.post(`file-upload`, formData, {
        headers: { "Content-Type": "multipart/form-data" }
      });

      setTimeout(() => {
        this.client.image = data.s3Files[0].completePath;
        console.log(data.s3Files[0].completePath, this.client.image);
      }, 500);
    },
    async onBillFileChange(e) {
      const file = e.target.files[0];
      console.log(file);

      if (!this.billing.url) this.billing.url = null;

      let formData = new FormData();

      formData.append("image", file);

      let { data } = await ApiService.post(`file-upload`, formData, {
        headers: { "Content-Type": "multipart/form-data" }
      });

      setTimeout(() => {
        this.billing.url = data.s3Files[0].completePath;
        console.log(data.s3Files[0].completePath, this.billing.url);
      }, 500);
    },
    formatDate(date) {
      console.log(date);
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    getEditor(ref) {
      return this.$refs[ref]
        .querySelector("iframe")
        .contentDocument.querySelector("body");
    },
    async getClient(id) {
      let { data } = await ApiService.get(`${this.resource}`, id);
      console.log({ ...data });
      return data;
    },
    async getWallet(id) {
      let { data } = await ApiService.query(`${this.resource}/${id}/wallet`, {
        fields: { operations: false }
      });
      console.log({ ...data });
      this.wallet = data;
      return data;
    },
    async getBills(id) {
      let { data } = await ApiService.get(`${this.resource}`, `${id}/billing`);
      console.log({ ...data });
      this.wallet = data;
      return data;
    },
    async update(e) {
      e.preventDefault();
      let { id, ...client } = {
        ...this.client,
        credits: parseInt(this.client.credits)
      };
      console.log(client);
      const submitButton = this.$refs["kt_update_client"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      submitButton.disabled = true;
      try {
        let { data } = await ApiService.patch(`${this.resource}/${id}`, client);
        console.log(data);
        Swal.fire({
          title: "",
          text: "El cliente ha sido guardado exitosamente!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary"
        }).then(function() {
          window.location.href = "#/clients";
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
        });
      } catch (e) {
        console.log(e);
        Swal.fire({
          title: "El cliente no ha sido actualizado",
          text: "Por favor revisa que los datos ingresados sean correctos",
          icon: "error",
          confirmButtonClass: "btn btn-secondary"
        });
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.disabled = false;
      }
    },
    async createBilling(e) {
      e.preventDefault();
      const submitButton = this.$refs["kt_create_billing"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      submitButton.disabled = true;
      try {
        let { data } = await ApiService.post(
          `${this.resource}/${this.client.id}/billing`,
          this.billing
        );
        console.log(data);
        this.billing = {
          date: "",
          amount: 0,
          description: "",
          url: "",
          createdAt: ""
        };
        this.wallet.bills = data;
        this.wallet.bills?.sort(function(a, b) {
          return new Date(b.date) - new Date(a.date);
        });
        Swal.fire({
          title: "",
          text: "La factura se ha generado exitosamente!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary"
        });
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.disabled = false;
        //resetFilePlaceholder();
      } catch (e) {
        console.log(e);
        Swal.fire({
          title: "La factura no ha sido generada",
          text: "Por favor intenta de nuevo mas tarde",
          icon: "error",
          confirmButtonClass: "btn btn-secondary"
        });
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.disabled = false;
      }
    },
    async deleteBilling(billingId) {
      try {
        await ApiService.delete(
          `${this.resource}/${this.client.id}/billing/${billingId}`
        );
        this.billing = {
          date: "",
          amount: 0,
          description: "",
          url: "",
          createdAt: ""
        };
        this.wallet.bills = this.wallet.bills.filter(
          bill => bill.id != billingId
        );
        this.wallet.bills?.sort(function(a, b) {
          return new Date(b.date) - new Date(a.date);
        });
        Swal.fire({
          title: "",
          text: "La factura se ha eliminado exitosamente!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary"
        });
        //resetFilePlaceholder();
      } catch (e) {
        console.log(e);
        Swal.fire({
          title: "La factura no ha sido eliminada",
          text: "Por favor intenta de nuevo mas tarde",
          icon: "error",
          confirmButtonClass: "btn btn-secondary"
        });
      }
    },
    async rechargeWallet(e) {
      e.preventDefault();
      const submitButton = this.$refs["kt_recharge_wallet"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      submitButton.disabled = true;
      try {
        let { data } = await ApiService.post(
          `${this.resource}/${this.client.id}/wallet?fields[operations]=false`,
          this.recharge
        );
        console.log(data);
        this.recharge = {};
        this.wallet = { ...data, bills: this.wallet.bills };
        this.wallet?.operations?.sort(function(a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        this.sortingList = {};
        this.sortListFor("createdAt");
        Swal.fire({
          title: "",
          text: "La recarga se ha realizado exitosamente!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary"
        });
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.disabled = false;
      } catch (e) {
        console.log(e);
        Swal.fire({
          title: "la recarga no ha sido realizada",
          text: "Por favor intenta de nuevo mas tarde",
          icon: "error",
          confirmButtonClass: "btn btn-secondary"
        });
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.disabled = false;
      }
    }
  },
  watch: {
    search: function() {
      this.getUserList();
    },
    userPerPage: function() {
      this.getUserList();
    },
    userCurrentPage: function() {
      this.getUserList();
    },
    // 'billing.amount':{
    //   handler: function(credits) {
    //     // Do something here.
    //     this.billing.amount = parseInt((credits+"").replace(".",""))
    //   },
    //   immediate: true,
    // },
    "client.credits": {
      handler: function(credits) {
        // Do something here.
        if (credits) this.client.credits = parseInt(credits);
      },
      immediate: true
    },
    "user.nickname": {
      handler: function(nickname) {
        // Do something here.
        if (nickname) this.user.nickname = nickname?.toLowerCase();
      },
      immediate: true
    },
    "recharge.credits": {
      handler: function(credits) {
        // Do something here.
        if (credits) this.recharge.credits = parseInt(credits);
      },
      immediate: true
    },
    $route: {
      handler: function(to) {
        // Do something here.
        console.log(to);
        if (to.path == "/clients/new") {
          this.user = {};
          this.client = {};
        }

        this.$forceUpdate();
      },
      immediate: true
    }
  },
  computed: {
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    }
  }
};
</script>
