var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card card-custom position-relative" },
    [
      _vm.showLoader ? _c("LoaderPartial") : _vm._e(),
      _c("div", { staticClass: "card-body p-0" }, [
        _c(
          "div",
          {
            staticClass: "wizard wizard-1",
            attrs: {
              id: "kt_wizard_v1",
              "data-wizard-state": "step-first",
              "data-wizard-clickable": "true"
            }
          },
          [
            _c("div", { staticClass: "wizard-nav border-bottom" }, [
              _c("div", { staticClass: "wizard-steps p-8 p-lg-10" }, [
                _c(
                  "div",
                  {
                    staticClass: "wizard-step",
                    attrs: { "data-wizard-type": "step" }
                  },
                  [
                    _c(
                      "div",
                      {
                        class:
                          "wizard-label row " + (_vm.client.id ? "edit" : "")
                      },
                      [
                        _c("i", {
                          staticClass: "wizard-icon flaticon-book col"
                        }),
                        _c("h3", { staticClass: "wizard-title col" }, [
                          _vm._v("Información General")
                        ])
                      ]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "wizard-step",
                    attrs: { "data-wizard-type": "step" }
                  },
                  [
                    _c(
                      "div",
                      {
                        class:
                          "wizard-label row " + (_vm.client.id ? "edit" : "")
                      },
                      [
                        _c("i", {
                          staticClass: "wizard-icon flaticon-piggy-bank col"
                        }),
                        _c("h3", { staticClass: "wizard-title col" }, [
                          _vm._v("Creditos Cliente")
                        ])
                      ]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "wizard-step",
                    attrs: { "data-wizard-type": "step" }
                  },
                  [
                    _c(
                      "div",
                      {
                        class:
                          "wizard-label row " + (_vm.client.id ? "edit" : "")
                      },
                      [
                        _c("i", {
                          staticClass: "wizard-icon flaticon-list col"
                        }),
                        _c("h3", { staticClass: "wizard-title col" }, [
                          _vm._v("Facturas Cliente")
                        ])
                      ]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "wizard-step",
                    attrs: { "data-wizard-type": "step" }
                  },
                  [
                    _c(
                      "div",
                      {
                        class:
                          "wizard-label row " + (_vm.client.id ? "edit" : "")
                      },
                      [
                        _c("i", {
                          staticClass: "wizard-icon flaticon-network col"
                        }),
                        _c("h3", { staticClass: "wizard-title col" }, [
                          _vm._v("Usuarios de Administración")
                        ])
                      ]
                    )
                  ]
                )
              ])
            ]),
            _c(
              "div",
              {
                staticClass:
                  "row justify-content-center my-10 px-8 my-lg-15 px-lg-10"
              },
              [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    {
                      staticClass: "form row justify-content-center",
                      attrs: { id: "kt_form" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "pb-5 col-xl-12 col-xxl-11 .offset-xxl-6",
                          attrs: { "data-wizard-type": "step-content" }
                        },
                        [
                          _c(
                            "h3",
                            { staticClass: "mb-10 font-weight-bold text-dark" },
                            [_vm._v(" Información general ")]
                          ),
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", [_vm._v("Logo")]),
                            _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "image-input image-input-outline",
                                  attrs: { id: "kt_profile_avatar" }
                                },
                                [
                                  _c("div", {
                                    staticClass: "image-input-wrapper",
                                    style: {
                                      backgroundImage:
                                        "url('" + _vm.photo + "')",
                                      backgroundPosition: "center center"
                                    }
                                  }),
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow",
                                      attrs: {
                                        "data-action": "change",
                                        "data-toggle": "tooltip",
                                        title: "",
                                        "data-original-title": "Change avatar"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fa fa-pen icon-sm text-muted"
                                      }),
                                      _c("input", {
                                        attrs: {
                                          type: "file",
                                          name: "profile_avatar",
                                          accept: ".png, .jpg, .jpeg"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.onFileChange($event)
                                          }
                                        }
                                      }),
                                      _c("input", {
                                        attrs: {
                                          type: "hidden",
                                          name: "profile_avatar_remove"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "span",
                                { staticClass: "form-text text-muted" },
                                [_vm._v("Tipos permitidos: png, jpg, jpeg.")]
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", [_vm._v("Nombre del cliente")]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.client.client,
                                  expression: "client.client"
                                }
                              ],
                              ref: "client",
                              staticClass:
                                "form-control form-control-lg form-control-solid",
                              attrs: {
                                type: "text",
                                name: "client",
                                placeholder: "Nombre del cliente"
                              },
                              domProps: { value: _vm.client.client },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.client,
                                    "client",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _c(
                              "span",
                              { staticClass: "form-text text-muted" },
                              [_vm._v("Ingrese el nombre del cliente")]
                            ),
                            !_vm.$v.client.client.required
                              ? _c(
                                  "div",
                                  { staticClass: "error text-primary" },
                                  [_vm._v(" Este campo es obligatorio ")]
                                )
                              : _vm._e(),
                            !_vm.$v.client.client.minLength
                              ? _c(
                                  "div",
                                  { staticClass: "error text-primary" },
                                  [
                                    _vm._v(
                                      " Son requeridos por lo menos 3 caracteres. "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]),
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", [_vm._v("Nombre de la instancia")]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.client.name,
                                  expression: "client.name"
                                }
                              ],
                              ref: "name",
                              staticClass:
                                "form-control form-control-lg form-control-solid",
                              attrs: {
                                type: "text",
                                name: "name",
                                placeholder: "Nombre de la instancia"
                              },
                              domProps: { value: _vm.client.name },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.client,
                                    "name",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _c(
                              "span",
                              { staticClass: "form-text text-muted" },
                              [_vm._v("Ingrese el nombre del cliente")]
                            ),
                            !_vm.$v.client.name.required
                              ? _c(
                                  "div",
                                  { staticClass: "error text-primary" },
                                  [_vm._v(" Este campo es obligatorio ")]
                                )
                              : _vm._e(),
                            !_vm.$v.client.name.minLength
                              ? _c(
                                  "div",
                                  { staticClass: "error text-primary" },
                                  [
                                    _vm._v(
                                      " Son requeridos por lo menos 3 caracteres. "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            !_vm.$v.client.name.notExists &&
                            !_vm.$v.client.name.$pending
                              ? _c(
                                  "div",
                                  { staticClass: "error text-primary" },
                                  [
                                    _vm._v(
                                      " El nombre de la instancia ya existe. "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-xl-12" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c("label", [_vm._v("Descripción")]),
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.client.description,
                                      expression: "client.description"
                                    }
                                  ],
                                  staticClass:
                                    "form-control form-control-solid form-control-lg",
                                  attrs: {
                                    type: "text",
                                    name: "description",
                                    placeholder: "Descripción del servicio"
                                  },
                                  domProps: { value: _vm.client.description },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.client,
                                        "description",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _c(
                                  "span",
                                  { staticClass: "form-text text-muted" },
                                  [
                                    _vm._v(
                                      "Agrega una descripción para el servicio"
                                    )
                                  ]
                                ),
                                !_vm.$v.client.description.required
                                  ? _c(
                                      "div",
                                      { staticClass: "error text-primary" },
                                      [_vm._v(" Este campo es obligatorio ")]
                                    )
                                  : _vm._e(),
                                !_vm.$v.client.description.minLength
                                  ? _c(
                                      "div",
                                      { staticClass: "error text-primary" },
                                      [
                                        _vm._v(
                                          " Son requeridos por lo menos 25 caracteres. "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ])
                          ]),
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", [_vm._v("Subdominio de Instancia")]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.client.tenant,
                                  expression: "client.tenant"
                                }
                              ],
                              staticClass:
                                "form-control form-control-solid form-control-lg",
                              attrs: {
                                type: "text",
                                name: "id",
                                placeholder: "Subdominio de la Instancia",
                                disabled: _vm.client.id ? "disabled" : false
                              },
                              domProps: { value: _vm.client.tenant },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.client,
                                    "tenant",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _c(
                              "span",
                              { staticClass: "form-text text-muted" },
                              [_vm._v("Identificador de acceso a la instancia")]
                            ),
                            !_vm.$v.client.tenant.required
                              ? _c(
                                  "div",
                                  { staticClass: "error text-primary" },
                                  [_vm._v(" Este campo es obligatorio ")]
                                )
                              : _vm._e(),
                            !_vm.$v.client.tenant.minLength
                              ? _c(
                                  "div",
                                  { staticClass: "error text-primary" },
                                  [
                                    _vm._v(
                                      " Son requeridos por lo menos 3 caracteres. "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            !_vm.$v.client.tenant.notExists &&
                            !_vm.$v.client.tenant.$pending
                              ? _c(
                                  "div",
                                  { staticClass: "error text-primary" },
                                  [
                                    _vm._v(
                                      " El nombre de subdominio ya existe. "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "pb-5 col-xl-12 col-xxl-11 .offset-xxl-6",
                          attrs: { "data-wizard-type": "step-content" }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "h4",
                              {
                                staticClass:
                                  "mb-10 font-weight-bold text-dark col"
                              },
                              [_vm._v(" Creditos del cliente ")]
                            ),
                            _vm.wallet
                              ? _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "mb-10 font-weight-bold text-dark col text-right"
                                  },
                                  [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("amount")(_vm.wallet.credits)
                                        )
                                      )
                                    ]),
                                    _vm._v(" creditos disponibles ")
                                  ]
                                )
                              : _vm._e()
                          ]),
                          !_vm.client.id
                            ? _c("div", { staticClass: "form-group" }, [
                                _c("label", [_vm._v("Creditos iniciales")]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "input-group input-group-lg input-group-solid"
                                  },
                                  [
                                    _vm._m(0),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.client.credits,
                                          expression: "client.credits"
                                        }
                                      ],
                                      ref: "initial-credits",
                                      staticClass:
                                        "form-control form-control-lg form-control-solid",
                                      attrs: {
                                        type: "number",
                                        min: "1",
                                        name: "initial-credits",
                                        placeholder: "Creditos del cliente"
                                      },
                                      domProps: { value: _vm.client.credits },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.client,
                                            "credits",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "form-text text-muted" },
                                  [
                                    _vm._v(
                                      "Los creditos se depositarán al momento de crear al cliente"
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          _vm.client.id
                            ? _c("div", [
                                _c("div", { staticClass: "form-group" }, [
                                  _c("label", [_vm._v("Creditos a recargar")]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "input-group input-group-lg input-group-solid"
                                    },
                                    [
                                      _vm._m(1),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.number",
                                            value: _vm.recharge.credits,
                                            expression: "recharge.credits",
                                            modifiers: { number: true }
                                          }
                                        ],
                                        ref: "credit-recarge",
                                        staticClass:
                                          "form-control form-control-lg form-control-solid",
                                        attrs: {
                                          type: "number",
                                          name: "credit-recarge",
                                          placeholder: "Numero de creditos"
                                        },
                                        domProps: {
                                          value: _vm.recharge.credits
                                        },
                                        on: {
                                          keydown: _vm.filterKey,
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.recharge,
                                              "credits",
                                              _vm._n($event.target.value)
                                            )
                                          },
                                          blur: function($event) {
                                            return _vm.$forceUpdate()
                                          }
                                        }
                                      })
                                    ]
                                  ),
                                  !_vm.$v.recharge.credits.required
                                    ? _c(
                                        "div",
                                        { staticClass: "error text-primary" },
                                        [_vm._v(" Este campo es obligatorio ")]
                                      )
                                    : _vm._e(),
                                  _c("br"),
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.recharge.comment,
                                        expression: "recharge.comment"
                                      }
                                    ],
                                    ref: "comment-recarge",
                                    staticClass:
                                      "form-control form-control-lg form-control-solid",
                                    attrs: {
                                      name: "comment-recarge",
                                      placeholder: "Descripción de la recarga"
                                    },
                                    domProps: { value: _vm.recharge.comment },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.recharge,
                                          "comment",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _c("br"),
                                  _c(
                                    "div",
                                    { staticStyle: { "text-align": "right" } },
                                    [
                                      _c(
                                        "button",
                                        {
                                          ref: "kt_recharge_wallet",
                                          staticClass: "btn btn-primary btn-lg",
                                          attrs: {
                                            disabled: _vm.$v.recharge.$invalid
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.rechargeWallet($event)
                                            }
                                          }
                                        },
                                        [_vm._v(" Recargar ")]
                                      )
                                    ]
                                  )
                                ]),
                                _c("div", { staticClass: "form-group" }, [
                                  _c(
                                    "h4",
                                    {
                                      staticClass:
                                        "mb-10 font-weight-bold text-dark"
                                    },
                                    [_vm._v(" Historial de recargas ")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "table-responsive" },
                                    [
                                      _c(
                                        "table",
                                        {
                                          staticClass:
                                            "table table-head-custom table-vertical-center table-head-bg table-borderless"
                                        },
                                        [
                                          _vm._m(2),
                                          _vm.wallet.operations
                                            ? _c(
                                                "tbody",
                                                [
                                                  _vm._l(
                                                    _vm.wallet.operations.slice(
                                                      (_vm.currentPage - 1) *
                                                        _vm.perPage,
                                                      _vm.currentPage *
                                                        _vm.perPage
                                                    ),
                                                    function(item, i) {
                                                      return [
                                                        _c("tr", { key: i }, [
                                                          _c("td", [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "font-weight-bold"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    new Date(
                                                                      item.createdAt
                                                                    )
                                                                      .toLocaleString()
                                                                      .split(
                                                                        " "
                                                                      )[0]
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]),
                                                          _c("td", [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "font-weight-bold",
                                                                style: {
                                                                  color:
                                                                    parseInt(
                                                                      item.credits
                                                                    ) < 0
                                                                      ? "red!important"
                                                                      : "black"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "amount"
                                                                    )(
                                                                      item.credits
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]),
                                                          _c("td", [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "font-weight-bold",
                                                                class: item.comment
                                                                  ? ""
                                                                  : "text-muted"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.comment ||
                                                                      "Sin comentarios"
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ])
                                                        ])
                                                      ]
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            : _vm._e()
                                        ]
                                      ),
                                      _c("div", [
                                        _c("div", { staticClass: "row" }, [
                                          _vm.wallet.operations
                                            ? _c(
                                                "div",
                                                { staticClass: "col m-1 " },
                                                [
                                                  _c("b-pagination", {
                                                    attrs: {
                                                      "total-rows":
                                                        _vm.wallet.operations
                                                          .length,
                                                      "per-page": _vm.perPage,
                                                      "page-click": "",
                                                      "aria-controls":
                                                        "my-table"
                                                    },
                                                    model: {
                                                      value: _vm.currentPage,
                                                      callback: function($$v) {
                                                        _vm.currentPage = $$v
                                                      },
                                                      expression: "currentPage"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "col m-1 text-right"
                                            },
                                            [
                                              _vm._v(" Elementos por pagina "),
                                              _c("b-form-select", {
                                                staticClass: "mb-3",
                                                staticStyle: { width: "70px" },
                                                attrs: {
                                                  options: _vm.optionPerPage,
                                                  "value-field": "item",
                                                  "text-field": "name",
                                                  "disabled-field": "notEnabled"
                                                },
                                                model: {
                                                  value: _vm.perPage,
                                                  callback: function($$v) {
                                                    _vm.perPage = $$v
                                                  },
                                                  expression: "perPage"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ])
                                      ])
                                    ]
                                  )
                                ])
                              ])
                            : _vm._e()
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "pb-5 col-xl-12 col-xxl-11 .offset-xxl-6",
                          attrs: { "data-wizard-type": "step-content" }
                        },
                        [
                          [
                            _c(
                              "v-dialog",
                              {
                                attrs: {
                                  "max-width": "520",
                                  persistent: "",
                                  "retain-focus": false
                                },
                                model: {
                                  value: _vm.billingDialog,
                                  callback: function($$v) {
                                    _vm.billingDialog = $$v
                                  },
                                  expression: "billingDialog"
                                }
                              },
                              [
                                _c(
                                  "v-card",
                                  { staticClass: "py-5 px-2" },
                                  [
                                    _c(
                                      "v-card-title",
                                      { staticClass: "headline" },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            "¿Está seguro que desea borrar esta factura?"
                                          )
                                        ])
                                      ]
                                    ),
                                    _c("v-card-text", [
                                      _vm._v(
                                        " Esta acción no puede ser reestablecida. Si aun así desea continuar presione en eliminar permanentemente. "
                                      ),
                                      _c("br")
                                    ]),
                                    _c(
                                      "v-card-actions",
                                      { staticClass: "justify-content-center" },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-secondary font-weight-bolder font-size-sm mr-3",
                                            on: {
                                              click: function() {
                                                _vm.billingDialog = false
                                              }
                                            }
                                          },
                                          [_vm._v(" Cancelar ")]
                                        ),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-primary font-weight-bolder font-size-sm mr-3",
                                            on: {
                                              click: function() {
                                                _vm.billingDialog = false
                                                _vm.deleteBilling(
                                                  _vm.deletableBilling.id
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(" Eliminar permanentemente ")]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          _vm._m(3),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", [_vm._v("Fecha de facturación")]),
                              _c("b-form-datepicker", {
                                staticClass:
                                  "form-control form-control-solid form-control-lg",
                                attrs: {
                                  id: "datepicker-facturation-date",
                                  "date-format-options": {
                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric"
                                  },
                                  locale: "es"
                                },
                                model: {
                                  value: _vm.billing.date,
                                  callback: function($$v) {
                                    _vm.$set(_vm.billing, "date", $$v)
                                  },
                                  expression: "billing.date"
                                }
                              }),
                              !_vm.$v.billing.date.required
                                ? _c(
                                    "div",
                                    { staticClass: "error text-primary" },
                                    [_vm._v(" Este campo es obligatorio ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", [_vm._v("Cantidad a facturar")]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "input-group input-group-lg input-group-solid"
                              },
                              [
                                _vm._m(4),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: _vm.billing.amount,
                                      expression: "billing.amount",
                                      modifiers: { number: true }
                                    }
                                  ],
                                  ref: "credit-recarge",
                                  staticClass:
                                    "form-control form-control-lg form-control-solid",
                                  attrs: {
                                    type: "number",
                                    name: "credit-recarge",
                                    min: "1",
                                    placeholder: "Numero de creditos"
                                  },
                                  domProps: { value: _vm.billing.amount },
                                  on: {
                                    keydown: _vm.filterKey,
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.billing,
                                        "amount",
                                        _vm._n($event.target.value)
                                      )
                                    },
                                    blur: function($event) {
                                      return _vm.$forceUpdate()
                                    }
                                  }
                                })
                              ]
                            ),
                            !_vm.$v.billing.amount.required
                              ? _c(
                                  "div",
                                  { staticClass: "error text-primary" },
                                  [_vm._v(" Este campo es obligatorio ")]
                                )
                              : _vm._e(),
                            !_vm.$v.billing.amount.minValue
                              ? _c(
                                  "div",
                                  { staticClass: "error text-primary" },
                                  [
                                    _vm._v(
                                      " La cantidad a facturar debe ser minimo de 1. "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]),
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", [_vm._v("Descripción")]),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.billing.description,
                                  expression: "billing.description"
                                }
                              ],
                              ref: "comment-recarge",
                              staticClass:
                                "form-control form-control-lg form-control-solid",
                              attrs: {
                                name: "comment-recarge",
                                placeholder: "Descripción de la recarga"
                              },
                              domProps: { value: _vm.billing.description },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.billing,
                                    "description",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", [_vm._v("Factura (Solo PDF)")]),
                              _c("b-form-file", {
                                staticClass:
                                  "form-control form-control-lg form-control-solid",
                                attrs: {
                                  state: Boolean(_vm.billing.url),
                                  placeholder:
                                    "Elige una factura o arrastrala aqui...",
                                  "drop-placeholder":
                                    "Arrastra aqui el archivo...",
                                  accept: ".pdf",
                                  id: "facturation"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.onBillFileChange($event)
                                  }
                                }
                              }),
                              !_vm.$v.billing.url.required
                                ? _c(
                                    "div",
                                    { staticClass: "error text-primary" },
                                    [_vm._v(" Este campo es obligatorio ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { "text-align": "right" } },
                            [
                              _c(
                                "button",
                                {
                                  ref: "kt_create_billing",
                                  staticClass: "btn btn-primary btn-lg",
                                  attrs: { disabled: _vm.$v.billing.$invalid },
                                  on: {
                                    click: function($event) {
                                      return _vm.createBilling($event)
                                    }
                                  }
                                },
                                [_vm._v(" Subir factura ")]
                              )
                            ]
                          ),
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "h4",
                              {
                                staticClass: "mb-10 font-weight-bold text-dark"
                              },
                              [_vm._v(" Historial de facturas ")]
                            ),
                            _c("div", { staticClass: "table-responsive" }, [
                              _c(
                                "table",
                                {
                                  staticClass:
                                    "table table-head-custom table-vertical-center table-head-bg table-borderless"
                                },
                                [
                                  _vm._m(5),
                                  _vm.wallet.bills
                                    ? _c(
                                        "tbody",
                                        [
                                          _vm._l(
                                            _vm.wallet.bills.slice(
                                              (_vm.billsCurrentPage - 1) *
                                                _vm.billsPerPage,
                                              _vm.billsCurrentPage *
                                                _vm.billsPerPage
                                            ),
                                            function(item, i) {
                                              return [
                                                _c("tr", { key: i }, [
                                                  _c("td", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "font-weight-bold"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.date
                                                              .split("-")
                                                              .reverse()
                                                              .join("/")
                                                          ) + " "
                                                        )
                                                      ]
                                                    )
                                                  ]),
                                                  _c("td", [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "font-weight-bold",
                                                        attrs: {
                                                          href: item.url
                                                        }
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "flaticon-download"
                                                        }),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item.id) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]),
                                                  _c("td", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "font-weight-bold",
                                                        class: item.description
                                                          ? ""
                                                          : "text-muted"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.description ||
                                                              "Sin descripción"
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]),
                                                  _c("td", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "font-weight-bold",
                                                        style: {
                                                          color:
                                                            parseInt(
                                                              item.amount
                                                            ) < 0
                                                              ? "red!important"
                                                              : "black"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("currency")(
                                                              item.amount
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c(
                                                        "b-badge",
                                                        {
                                                          staticClass: "m-0",
                                                          staticStyle: {
                                                            cursor: "pointer"
                                                          },
                                                          attrs: {
                                                            variant: "primary"
                                                          },
                                                          on: {
                                                            click: function() {
                                                              _vm.billingDialog = true
                                                              _vm.deletableBilling = item
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("✖︎")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ])
                                              ]
                                            }
                                          )
                                        ],
                                        2
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _c("div", [
                                _c("div", { staticClass: "row" }, [
                                  _vm.wallet.bills
                                    ? _c(
                                        "div",
                                        { staticClass: "col m-1 " },
                                        [
                                          _c("b-pagination", {
                                            attrs: {
                                              "total-rows":
                                                _vm.wallet.bills.length,
                                              "per-page": _vm.billsPerPage,
                                              "page-click": "",
                                              "aria-controls": "my-table"
                                            },
                                            model: {
                                              value: _vm.billsCurrentPage,
                                              callback: function($$v) {
                                                _vm.billsCurrentPage = $$v
                                              },
                                              expression: "billsCurrentPage"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "col m-1 text-right" },
                                    [
                                      _vm._v(" Elementos por pagina "),
                                      _c("b-form-select", {
                                        staticClass: "mb-3",
                                        staticStyle: { width: "70px" },
                                        attrs: {
                                          options: _vm.optionPerPage,
                                          "value-field": "item",
                                          "text-field": "name",
                                          "disabled-field": "notEnabled"
                                        },
                                        model: {
                                          value: _vm.billsPerPage,
                                          callback: function($$v) {
                                            _vm.billsPerPage = $$v
                                          },
                                          expression: "billsPerPage"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              ])
                            ])
                          ])
                        ],
                        2
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "pb-5 col-12",
                          attrs: { "data-wizard-type": "step-content" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "card card-custom card-stretch gutter-b",
                              staticStyle: { "box-shadow": "none" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "card-header border-0 pb-5 pt-0"
                                },
                                [
                                  _c(
                                    "h2",
                                    {
                                      staticClass:
                                        "card-title align-items-start flex-column font-weight-bold text-dark col pt-0 mt-0"
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "card-label font-weight-bolder text-dark"
                                        },
                                        [_vm._v("Listado de usuarios")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-muted mt-3 font-weight-bold font-size-sm"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.rows) +
                                              " Administradores encontradas en el sistema"
                                          )
                                        ]
                                      ),
                                      !_vm.$v.client.client.required
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "error text-primary"
                                            },
                                            [
                                              _vm._v(
                                                " Este campo es obligatorio "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      !_vm.$v.client.client.minLength
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "error text-primary"
                                            },
                                            [
                                              _vm._v(
                                                " Son requeridos por lo menos 3 caracteres. "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "card-toolbar" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          directives: [
                                            {
                                              name: "b-modal",
                                              rawName:
                                                "v-b-modal.tenant-admin-modal",
                                              modifiers: {
                                                "tenant-admin-modal": true
                                              }
                                            }
                                          ],
                                          staticClass:
                                            "btn btn-primary font-weight-bolder font-size-sm mr-3",
                                          on: {
                                            click: function() {
                                              _vm.user = {}
                                              _vm.asignId("new")
                                            }
                                          }
                                        },
                                        [_vm._v(" Nuevo Usuario ")]
                                      ),
                                      _c(
                                        "b-modal",
                                        {
                                          ref: "tenant-admin-modal",
                                          attrs: {
                                            id: "tenant-admin-modal",
                                            "hide-footer": "",
                                            title:
                                              "" +
                                              (!_vm.client.id
                                                ? "Crear nuevo administrador"
                                                : "Editar administrador")
                                          }
                                        },
                                        [
                                          _c("div", { staticClass: "form" }, [
                                            _c(
                                              "div",
                                              { staticClass: "card-body" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "form-group "
                                                  },
                                                  [
                                                    _c("label", [
                                                      _vm._v("Nombre completo")
                                                    ]),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: _vm.user.name,
                                                          expression:
                                                            "user.name"
                                                        }
                                                      ],
                                                      ref: "name",
                                                      staticClass:
                                                        "form-control form-control-lg form-control-solid",
                                                      attrs: { type: "text" },
                                                      domProps: {
                                                        value: _vm.user.name
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            _vm.user,
                                                            "name",
                                                            $event.target.value
                                                          )
                                                        }
                                                      }
                                                    }),
                                                    !_vm.$v.user.name.required
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "error text-primary"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Este campo es obligatorio "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    !_vm.$v.user.name.minLength
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "error text-primary"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Son requeridos por lo menos 5 caracteres. "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "form-group "
                                                  },
                                                  [
                                                    _c("label", [
                                                      _vm._v("Alias")
                                                    ]),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.user.nickname,
                                                          expression:
                                                            "user.nickname"
                                                        }
                                                      ],
                                                      ref: "nickname",
                                                      staticClass:
                                                        "form-control form-control-lg form-control-solid",
                                                      attrs: { type: "text" },
                                                      domProps: {
                                                        value: _vm.user.nickname
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            _vm.user,
                                                            "nickname",
                                                            $event.target.value
                                                          )
                                                        }
                                                      }
                                                    }),
                                                    !_vm.$v.user.nickname
                                                      .required
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "error text-primary"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Este campo es obligatorio "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    !_vm.$v.user.nickname
                                                      .minLength
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "error text-primary"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Son requeridos por lo menos 5 caracteres. "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    !_vm.$v.user.nickname
                                                      .notExists &&
                                                    !_vm.$v.user.nickname
                                                      .$pending
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "error text-primary"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " El alias ingresado ya existe. "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    !_vm.$v.user.nickname.regex
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "error text-primary"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " El alias no puede contener espacios ni caracteres especiales. "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "form-group "
                                                  },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        "Correo Electrónico"
                                                      )
                                                    ]),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "input-group input-group-lg input-group-solid"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "input-group-prepend"
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "input-group-text"
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "la la-at"
                                                                })
                                                              ]
                                                            )
                                                          ]
                                                        ),
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.user.email,
                                                              expression:
                                                                "user.email"
                                                            }
                                                          ],
                                                          ref: "email",
                                                          staticClass:
                                                            "form-control form-control-lg form-control-solid",
                                                          attrs: {
                                                            type: "text",
                                                            placeholder:
                                                              "Email",
                                                            readonly: !!_vm.user
                                                              .id
                                                          },
                                                          domProps: {
                                                            value:
                                                              _vm.user.email
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.$set(
                                                                _vm.user,
                                                                "email",
                                                                $event.target
                                                                  .value
                                                              )
                                                            }
                                                          }
                                                        })
                                                      ]
                                                    ),
                                                    !_vm.$v.user.email.required
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "error text-primary"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Este campo es obligatorio "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    !_vm.$v.user.email.email
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "error text-primary"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Formato de email no válido "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    !_vm.$v.user.email
                                                      .notExists &&
                                                    !_vm.$v.user.email.$pending
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "error text-primary"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " El email ingresado ya existe. "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                ),
                                                !_vm.user.id
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group "
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v("Contraseña")
                                                        ]),
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.user
                                                                  .password,
                                                              expression:
                                                                "user.password"
                                                            }
                                                          ],
                                                          ref: "password",
                                                          staticClass:
                                                            "form-control form-control-lg form-control-solid",
                                                          attrs: {
                                                            type: "password"
                                                          },
                                                          domProps: {
                                                            value:
                                                              _vm.user.password
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.$set(
                                                                _vm.user,
                                                                "password",
                                                                $event.target
                                                                  .value
                                                              )
                                                            }
                                                          }
                                                        }),
                                                        !_vm.$v.user.password
                                                          .required
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "error text-primary"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Este campo es obligatorio "
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        !_vm.$v.user.password
                                                          .minLength
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "error text-primary"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Son requeridos por lo menos 8 caracteres. "
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                !_vm.user.id
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group "
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            "Repetir Contraseña"
                                                          )
                                                        ]),
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.user
                                                                  .repeatPassword,
                                                              expression:
                                                                "user.repeatPassword"
                                                            }
                                                          ],
                                                          ref: "password",
                                                          staticClass:
                                                            "form-control form-control-lg form-control-solid",
                                                          attrs: {
                                                            type: "password"
                                                          },
                                                          domProps: {
                                                            value:
                                                              _vm.user
                                                                .repeatPassword
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.$set(
                                                                _vm.user,
                                                                "repeatPassword",
                                                                $event.target
                                                                  .value
                                                              )
                                                            }
                                                          }
                                                        }),
                                                        !_vm.$v.user
                                                          .repeatPassword
                                                          .sameAsPassword
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "error text-primary"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Las contraseñas no coinciden "
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                !!_vm.user.id
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group row"
                                                      },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "col-12 col-form-label"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Fecha de creación"
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-12"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.user
                                                                    .createdAt
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "div",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-secondary font-weight-bolder font-size-sm",
                                                        on: {
                                                          click: function() {
                                                            _vm.$refs[
                                                              "tenant-admin-modal"
                                                            ].hide()
                                                            _vm.user = {}
                                                          }
                                                        }
                                                      },
                                                      [_vm._v(" Cancelar ")]
                                                    ),
                                                    !!_vm.user.id
                                                      ? _c(
                                                          "button",
                                                          {
                                                            ref:
                                                              "kt_update_user",
                                                            staticClass:
                                                              "btn btn-primary font-weight-bolder font-size-sm",
                                                            attrs: {
                                                              disabled:
                                                                _vm.$v.editUser
                                                                  .$invalid
                                                            },
                                                            on: {
                                                              click: function() {
                                                                _vm.$refs[
                                                                  "tenant-admin-modal"
                                                                ].hide()
                                                                _vm.updateUser()
                                                              }
                                                            }
                                                          },
                                                          [_vm._v(" Guardar ")]
                                                        )
                                                      : _vm._e(),
                                                    !_vm.user.id
                                                      ? _c(
                                                          "button",
                                                          {
                                                            ref:
                                                              "kt_submit_user",
                                                            staticClass:
                                                              "btn btn-primary font-weight-bolder font-size-sm",
                                                            attrs: {
                                                              disabled:
                                                                _vm.$v.user
                                                                  .$invalid
                                                            },
                                                            on: {
                                                              click: function() {
                                                                _vm.$refs[
                                                                  "tenant-admin-modal"
                                                                ].hide()
                                                                _vm.submitUser()
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Crear usuario "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "card-body pt-0 pb-3" },
                                [
                                  _c("div", [
                                    _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "input-group input-group-lg input-group-solid mb-8"
                                        },
                                        [
                                          _vm._m(6),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.search,
                                                expression: "search"
                                              }
                                            ],
                                            staticClass:
                                              "form-control form-control-solid form-control-lg inline",
                                            attrs: {
                                              type: "text",
                                              name: "address1",
                                              placeholder: "Busqueda",
                                              "append-icon": "search",
                                              label: "Search",
                                              "hide-details": ""
                                            },
                                            domProps: { value: _vm.search },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.search = $event.target.value
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    ]),
                                    _c("div", { staticClass: "tab-content" }, [
                                      _c(
                                        "div",
                                        { staticClass: "table-responsive" },
                                        [
                                          _c(
                                            "table",
                                            {
                                              staticClass:
                                                "table table-head-custom table-vertical-center table-head-bg table-borderless"
                                            },
                                            [
                                              _c("thead", [
                                                _c(
                                                  "tr",
                                                  { staticClass: "text-left" },
                                                  [
                                                    _c(
                                                      "th",
                                                      {
                                                        staticClass: "hover",
                                                        staticStyle: {
                                                          "min-width": "100px"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.userSortListFor(
                                                              "email"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(" Email "),
                                                        _vm.userSortingList
                                                          .email == "DESC"
                                                          ? _c("i", {
                                                              staticClass:
                                                                "fa fa-angle-up",
                                                              attrs: {
                                                                "aria-hidden":
                                                                  "true"
                                                              }
                                                            })
                                                          : _vm._e(),
                                                        _vm.userSortingList
                                                          .email == "ASC"
                                                          ? _c("i", {
                                                              staticClass:
                                                                "fa fa-angle-down",
                                                              attrs: {
                                                                "aria-hidden":
                                                                  "true"
                                                              }
                                                            })
                                                          : _vm._e()
                                                      ]
                                                    ),
                                                    _c(
                                                      "th",
                                                      {
                                                        staticStyle: {
                                                          "min-width": "100px"
                                                        }
                                                      },
                                                      [_vm._v("Estado")]
                                                    ),
                                                    _c(
                                                      "th",
                                                      {
                                                        staticClass: "hover",
                                                        staticStyle: {
                                                          "min-width": "100px"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.userSortListFor(
                                                              "createdAt"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(" Creación "),
                                                        _vm.userSortingList
                                                          .createdAt == "DESC"
                                                          ? _c("i", {
                                                              staticClass:
                                                                "fa fa-angle-up",
                                                              attrs: {
                                                                "aria-hidden":
                                                                  "true"
                                                              }
                                                            })
                                                          : _vm._e(),
                                                        _vm.userSortingList
                                                          .createdAt == "ASC"
                                                          ? _c("i", {
                                                              staticClass:
                                                                "fa fa-angle-down",
                                                              attrs: {
                                                                "aria-hidden":
                                                                  "true"
                                                              }
                                                            })
                                                          : _vm._e()
                                                      ]
                                                    ),
                                                    _c("th", {
                                                      staticStyle: {
                                                        "min-width": "100px"
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]),
                                              _c(
                                                "tbody",
                                                [
                                                  _vm._l(_vm.admins, function(
                                                    item,
                                                    i
                                                  ) {
                                                    return [
                                                      _c("tr", { key: i }, [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "pl-0 py-8"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex align-items-center"
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "symbol symbol-50 symbol-light mr-4"
                                                                  },
                                                                  [
                                                                    !item.image
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "symbol-label",
                                                                            staticStyle: {
                                                                              overflow:
                                                                                "hidden"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "inline-svg",
                                                                              {
                                                                                attrs: {
                                                                                  src:
                                                                                    "media/svg/icons/General/User.svg"
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "symbol-label",
                                                                            staticStyle: {
                                                                              overflow:
                                                                                "hidden"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "img",
                                                                              {
                                                                                staticClass:
                                                                                  "h-100 align-self-end",
                                                                                attrs: {
                                                                                  src:
                                                                                    item.image,
                                                                                  alt:
                                                                                    ""
                                                                                }
                                                                              }
                                                                            )
                                                                          ]
                                                                        )
                                                                  ]
                                                                ),
                                                                _c("div", [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      directives: [
                                                                        {
                                                                          name:
                                                                            "b-modal",
                                                                          rawName:
                                                                            "v-b-modal.tenant-admin-modal",
                                                                          modifiers: {
                                                                            "tenant-admin-modal": true
                                                                          }
                                                                        }
                                                                      ],
                                                                      staticClass:
                                                                        "text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg",
                                                                      staticStyle: {
                                                                        cursor:
                                                                          "pointer"
                                                                      },
                                                                      on: {
                                                                        click: function() {
                                                                          _vm.user = Object.assign(
                                                                            {},
                                                                            item
                                                                          )
                                                                          _vm.asignId(
                                                                            item.id
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.email
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          [
                                                            item.active
                                                              ? _c(
                                                                  "b-badge",
                                                                  {
                                                                    staticClass:
                                                                      "mr-1",
                                                                    attrs: {
                                                                      variant:
                                                                        "success"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Activo"
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            !item.active
                                                              ? _c(
                                                                  "b-badge",
                                                                  {
                                                                    staticClass:
                                                                      "mr-1",
                                                                    attrs: {
                                                                      variant:
                                                                        "danger"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Inactivo"
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "text-muted font-weight-bold d-block"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.company_desc
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c("td", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-muted font-weight-bold d-block"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  new Date(
                                                                    item.createdAt
                                                                  )
                                                                    .toLocaleString()
                                                                    .split(
                                                                      " "
                                                                    )[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "pr-0 text-right"
                                                          },
                                                          [
                                                            _c(
                                                              "b-dropdown",
                                                              {
                                                                attrs: {
                                                                  id:
                                                                    "dropdown-1",
                                                                  text:
                                                                    "Acciones"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "b-dropdown-item",
                                                                  {
                                                                    directives: [
                                                                      {
                                                                        name:
                                                                          "b-modal",
                                                                        rawName:
                                                                          "v-b-modal.tenant-admin-modal",
                                                                        modifiers: {
                                                                          "tenant-admin-modal": true
                                                                        }
                                                                      }
                                                                    ],
                                                                    on: {
                                                                      click: function() {
                                                                        _vm.user = Object.assign(
                                                                          {},
                                                                          item
                                                                        )
                                                                        _vm.asignId(
                                                                          item.id
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Editar"
                                                                    )
                                                                  ]
                                                                ),
                                                                item.active
                                                                  ? _c(
                                                                      "b-dropdown-item",
                                                                      {
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.disableUser(
                                                                              item.id
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticStyle: {
                                                                              color:
                                                                                "red"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "Desactivar"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                !item.active
                                                                  ? _c(
                                                                      "b-dropdown-item",
                                                                      {
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.enableUser(
                                                                              item.id
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {},
                                                                          [
                                                                            _vm._v(
                                                                              "Activar"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _c(
                                                                  "b-dropdown-item",
                                                                  {
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.deleteUserModal(
                                                                          item
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle: {
                                                                          color:
                                                                            "red"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Eliminar"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            [
                                                              _c(
                                                                "v-dialog",
                                                                {
                                                                  attrs: {
                                                                    "max-width":
                                                                      "520",
                                                                    persistent:
                                                                      "",
                                                                    "retain-focus": false
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.dialog,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.dialog = $$v
                                                                    },
                                                                    expression:
                                                                      "dialog"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-card",
                                                                    {
                                                                      staticClass:
                                                                        "py-5 px-2"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-card-title",
                                                                        {
                                                                          staticClass:
                                                                            "headline"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "¿Está seguro que desea borrar este usuario?"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-card-text",
                                                                        [
                                                                          _vm._v(
                                                                            " Esta acción no puede ser reestablecida. Si aun así desea continuar, escriba el nombre del usuario ("
                                                                          ),
                                                                          _c(
                                                                            "b",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .deletableAdmin
                                                                                    .email
                                                                                )
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            ") y luego presione en Eliminar permanentemente. "
                                                                          ),
                                                                          _c(
                                                                            "br"
                                                                          ),
                                                                          _c(
                                                                            "br"
                                                                          ),
                                                                          _c(
                                                                            "input",
                                                                            {
                                                                              directives: [
                                                                                {
                                                                                  name:
                                                                                    "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    _vm.deletableAdminEmail,
                                                                                  expression:
                                                                                    "deletableAdminEmail"
                                                                                }
                                                                              ],
                                                                              staticClass:
                                                                                "form-control form-control-solid form-control-lg inline mb-2",
                                                                              attrs: {
                                                                                type:
                                                                                  "text",
                                                                                name:
                                                                                  "deletable-name",
                                                                                placeholder:
                                                                                  "escribe: " +
                                                                                  _vm
                                                                                    .deletableAdmin
                                                                                    .email,
                                                                                "hide-details":
                                                                                  ""
                                                                              },
                                                                              domProps: {
                                                                                value:
                                                                                  _vm.deletableAdminEmail
                                                                              },
                                                                              on: {
                                                                                input: function(
                                                                                  $event
                                                                                ) {
                                                                                  if (
                                                                                    $event
                                                                                      .target
                                                                                      .composing
                                                                                  ) {
                                                                                    return
                                                                                  }
                                                                                  _vm.deletableAdminEmail =
                                                                                    $event.target.value
                                                                                }
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-card-actions",
                                                                        {
                                                                          staticClass:
                                                                            "justify-content-center"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "button",
                                                                            {
                                                                              staticClass:
                                                                                "btn btn-secondary font-weight-bolder font-size-sm mr-3",
                                                                              on: {
                                                                                click: function() {
                                                                                  _vm.dialog = false
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " Cancelar "
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "button",
                                                                            {
                                                                              staticClass:
                                                                                "btn btn-primary font-weight-bolder font-size-sm mr-3",
                                                                              attrs: {
                                                                                disabled:
                                                                                  _vm
                                                                                    .deletableAdmin
                                                                                    .email !=
                                                                                  _vm.deletableAdminEmail
                                                                              },
                                                                              on: {
                                                                                click: function() {
                                                                                  _vm.dialog = false
                                                                                  _vm.deleteUser(
                                                                                    _vm
                                                                                      .deletableAdmin
                                                                                      .id
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " Eliminar permanentemente "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          ],
                                                          2
                                                        )
                                                      ])
                                                    ]
                                                  })
                                                ],
                                                2
                                              )
                                            ]
                                          ),
                                          _c("div", [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col m-1 " },
                                                [
                                                  _c("b-pagination", {
                                                    attrs: {
                                                      "total-rows":
                                                        _vm.userRows,
                                                      "per-page":
                                                        _vm.userPerPage,
                                                      "page-click": "",
                                                      "aria-controls":
                                                        "my-table"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.userCurrentPage,
                                                      callback: function($$v) {
                                                        _vm.userCurrentPage = $$v
                                                      },
                                                      expression:
                                                        "userCurrentPage"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col m-1 text-right"
                                                },
                                                [
                                                  _vm._v(
                                                    " Elementos por pagina "
                                                  ),
                                                  _c("b-form-select", {
                                                    staticClass: "mb-3",
                                                    staticStyle: {
                                                      width: "70px"
                                                    },
                                                    attrs: {
                                                      options:
                                                        _vm.optionPerPage,
                                                      "value-field": "item",
                                                      "text-field": "name",
                                                      "disabled-field":
                                                        "notEnabled"
                                                    },
                                                    model: {
                                                      value: _vm.userPerPage,
                                                      callback: function($$v) {
                                                        _vm.userPerPage = $$v
                                                      },
                                                      expression: "userPerPage"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ])
                                          ])
                                        ]
                                      )
                                    ])
                                  ])
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "pb-5 col-xl-12 col-xxl-11 .offset-xxl-6",
                          attrs: { "data-wizard-type": "step-content" }
                        },
                        [
                          _c(
                            "h3",
                            { staticClass: "mb-10 font-weight-bold text-dark" },
                            [_vm._v(" Estadisticas de uso ")]
                          ),
                          !_vm.client.id
                            ? _c("div", [
                                _vm._v(
                                  " Debe crear el servicio antes de poder ver estadisticas "
                                )
                              ])
                            : _vm._e(),
                          _vm.client.id
                            ? _c("div", [_vm._v("Estadisticas")])
                            : _vm._e()
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between border-top pt-10 col-xl-12 col-xxl-9 .offset-xxl-6"
                        },
                        [
                          _c("div", { staticClass: "mr-2" }),
                          _c("div", [
                            _c(
                              "button",
                              {
                                ref: "kt_update_client",
                                staticClass: "btn btn-success btn-lg",
                                attrs: { disabled: _vm.$v.client.$invalid },
                                on: { click: _vm.update }
                              },
                              [_vm._v(" Guardar ")]
                            )
                          ])
                        ]
                      )
                    ]
                  )
                ])
              ]
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _c("i", { staticClass: "la" }, [_vm._v("C")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _c("i", { staticClass: "la" }, [_vm._v("C")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "text-left" }, [
        _c("th", {}, [_vm._v("Fecha")]),
        _c("th", {}, [_vm._v("Créditos")]),
        _c("th", [
          _c("span", { staticClass: "text-dark-75" }, [_vm._v("Comentario ")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("h4", { staticClass: "mb-10 font-weight-bold text-dark col" }, [
        _vm._v(" Facturación ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _c("i", { staticClass: "la" }, [_vm._v("$")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "text-left" }, [
        _c("th", {}, [_vm._v("Fecha")]),
        _c("th", {}, [_vm._v("Factura ID")]),
        _c("th", { staticClass: "pl-7" }, [
          _c("span", {}, [_vm._v("Descripción")])
        ]),
        _c("th", {}, [_vm._v("Cantidad")]),
        _c("th", {})
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _c("i", { staticClass: "la la-search" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }