import { render, staticRenderFns } from "./EditClient.vue?vue&type=template&id=326aefc8&scoped=true&"
import script from "./EditClient.vue?vue&type=script&lang=js&"
export * from "./EditClient.vue?vue&type=script&lang=js&"
import style0 from "./EditClient.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./EditClient.vue?vue&type=style&index=1&v-if=client.id&lang=css&"
import style2 from "./EditClient.vue?vue&type=style&index=2&id=326aefc8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "326aefc8",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VCard,VCardActions,VCardText,VCardTitle,VDialog})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('326aefc8')) {
      api.createRecord('326aefc8', component.options)
    } else {
      api.reload('326aefc8', component.options)
    }
    module.hot.accept("./EditClient.vue?vue&type=template&id=326aefc8&scoped=true&", function () {
      api.rerender('326aefc8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/view/pages/clients/EditClient.vue"
export default component.exports